.App {
  text-align: center;
}

.Title { 
  text-align: center;
}

table {
  margin: auto;
}

td {
  padding: 5px;
}

.Column-name {
  width: 150px;
}

.Column-price {
  width: 45px;
}

.Delete-button {
  font-size: 4px;
}

.Checked-button {
  background-color: aquamarine;
  border: 1px;
}

.Not-checked-button {
  border: 1px;
}

.Payment-button {
  border: 0px;
}

.Cancel-payment-button {
  background-color: yellow;
  border: 0px;
}
